import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div className="conteudo_geral">
          <noscript
            aria-hidden="true"
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQDRJ93" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>',
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n// **** Scroll na pag 'como-funciona' : ****\njQuery(window).on('load', function() {\nvar numberSlides = 3;\t\t//**** Alterar este nr sempre que for necessario incluir novas div com conteudo nesta pagina especifica; corresponde ao nr total de divs\nvar distance = 500;\nvar timing = 750;\njQuery('#div_pageNr').html('1');\njQuery('#div_setacima').hide();\njQuery('#div_setacima').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) - 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >= numberSlides ){\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\njQuery('#div_setabaixo').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) + 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >=2){\t\t\t//**** Alterar este nr sempre que for necessario ajustar o nr de divs com conteudo a mostrar nesta pagina especifica\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\n});\n",
            }}
          />
          <div id="head_geral" className="container">
            <div className="container">
              <header id="head" role="banner">
                <div className="five columns alpha">
                  <div className="bisnaga1" />
                </div>
                <div className="menu_linha_cima" />
                <div className="menu_linha_abaixo" />
                <div
                  className="eleven columns omega"
                  style={{
                    float: "right",
                  }}
                >
                  <div className="cabecalho_esq">
                    <div className="div_logo">
                      <a href="/" title="Início">
                        <img
                          className="img_logo"
                          src="/sites/default/files/color/responsive/logo.png"
                          alt="Início"
                        />
                      </a>{" "}
                    </div>
                  </div>
                  <nav id="navigation" role="navigation">
                    <div id="main-menu">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/o-que-e-biafine">O que é biafine®</a>
                        </li>
                        <li className="leaf">
                          <a
                            href="/quando-e-como-aplicar"  
                          >
                            Quando e como <br/> aplicar
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/como-funciona">Como funciona</a>
                        </li>
                        <li className="leaf active-trail">
                          <a href="/balsamo-multi-reparador-apaziguante" className="active-trail active"><span className="blue-text">Cica</span> biafine® <br/><span className="align-leaf">Cosmético</span></a>
                        </li>
                        <li className="last leaf">
                          <a href="/contactos">Contactos</a>
                        </li>
                      </ul>{" "}
                    </div>
                  </nav>
                </div>
              </header>
            </div>
          </div>
          <div className="container" id="content-contain">
            <div className="menu_shadow" />
            <div id="content" className="sixteen columns">
              <div id="breadcrumbs">
                <h2 className="element-invisible">Está aqui</h2>
                <nav className="breadcrumb">
                  <a href="/">Início</a> » Bálsamo Multi-Reparador Apaziguante
                </nav>
              </div>
              <section id="post-content" role="main">
                <h1 className="page-title">
                  Bálsamo Multi-Reparador Apaziguante
                </h1>
                <div className="region region-content">
                  <div id="block-system-main" className="block block-system">
                    <div className="content">
                      <span
                        property="dc:title"
                        content="Bálsamo Multi-Reparador Apaziguante"
                        className="rdf-meta element-hidden"
                      />
                      <span
                        property="sioc:num_replies"
                        content="0"
                        datatype="xsd:integer"
                        className="rdf-meta element-hidden"
                      />
                      <div className="content node-page">
                        <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                          <div className="field-items">
                            <div
                              className="field-item even"
                              property="content:encoded"
                            >
                              <div>
                                <div>
                                  <br />
                                  <ul>
                                    <li className="textoBlocoInicialCinza">
                                      <strong>Estimula </strong>o processo de
                                      regeneração da pele em pele danificada
                                      para 72% dos voluntários*
                                    </li>
                                    <li className="textoBlocoInicialCinza">
                                      <strong>Melhora</strong> o aspeto das
                                      marcas para 95% dos voluntários**
                                    </li>
                                    <li className="textoBlocoInicialCinza">
                                      <strong>Alivia </strong> a sensação de
                                      irritação para 74% dos voluntários*
                                    </li>
                                    <li className="textoBlocoInicialCinza">
                                      <strong>
                                        A vermelhidão das cicatrizes
                                      </strong>{" "}
                                      melhorou para 95% dos voluntários **
                                    </li>
                                    <li className="textoBlocoInicialCinza">
                                      <strong>Ajuda a restaurar</strong> a
                                      barreira da pele para 74% dos voluntários*
                                    </li>
                                    <li className="textoBlocoInicialCinza">
                                      <strong>Limita</strong> a proliferação
                                      bacteriana
                                    </li>
                                  </ul>

                                  <img
                                    alt="tubo BIAFINE"
                                    className="img_text1 resize-img"
                                    src="/sites/default/files/BiafinePT_06.png"
                                  />
                                </div>
                                <div>&nbsp;</div>
                              </div>

                              <div>
                                <p className="textoBlocoCinzaEscuro">
                                  <strong>
                                    {" "}
                                    Fórmula de elevada tolerância, desenvolvida
                                    com dermatologistas e clinicamente testada.
                                  </strong>
                                  <br />
                                  *Estudo clínico com questionário de
                                  autoavaliação, 54 voluntários incluindo bebés
                                  com pele seca ou muito seca, adolescentes com
                                  marcas/cicatrizes de acne e adultos com
                                  cicatrizes pós cirurgicas, aplicação 2 vezes
                                  por dia, resultados na 6ª semana
                                  <br />
                                  **Estudo clínico com questionário de
                                  autoavaliação, 19 voluntários com cicatrizes
                                  pós cirurgicas, aplicação 2 vezes por dia,
                                  resultados na 6ª semana
                                </p>
                                <img
                                  alt="tubo BIAFINE"
                                  className="img_text1 resize-img1"
                                  src="/sites/default/files/BiafinePT_11.png"
                                />
                              </div>
                              <div className="space-around">
                                <h3 className="first-h3">Indicações:</h3>

                                <p className="textoBlocoCinzaEscuro">
                                  Desenvolvido especificamente para peles
                                  danificadas com cicatrizes e marcas* (não
                                  abertas e superficiais causadas por:
                                </p>

                                <br />
                                <ul>
                                  <li className="textoBlocoInicialCinza">
                                    Acne
                                  </li>
                                  <li className="textoBlocoInicialCinza">
                                    Eritema
                                  </li>
                                  <li className="textoBlocoInicialCinza">
                                    Fissuras, pele gretada, pequenos cortes,
                                    arranhões
                                  </li>
                                  <li className="textoBlocoInicialCinza">
                                    Após cirurgia, incluindo cesariana
                                  </li>
                                  <li className="textoBlocoInicialCinza">
                                    Após atos dermatológicos e peelings químicos
                                    leves
                                  </li>
                                  <li className="textoBlocoInicialCinza">
                                    Irritações pós depilação ou barbear
                                  </li>
                                </ul>

                                <p
                                  className="textoBlocoCinzaEscuro"
                                  style={{
                                    "font-size": "11px",
                                  }}
                                >
                                  <h3 className="second-h3">
                                    Instruções de utilização:
                                  </h3>
                                  <ul>
                                    <li className="textoBlocoInicialCinza">
                                      Adequado para o rosto e o corpo, para a
                                      pele sensível de toda a família, a partir
                                      dos 3 meses.
                                    </li>
                                    <li className="textoBlocoInicialCinza">
                                      Aplicar 2 vezes por dia sobre pele
                                      previamente limpa. Não aplicar em feridas
                                      abertas ou infetadas.
                                    </li>
                                    <li className="textoBlocoInicialCinza">
                                      Aconselhe-se com o seu farmacêutico.
                                    </li>
                                  </ul>
                                </p>
                                <h3 className="third-h3">
                                  Cicatrizes de acne:
                                </h3>
                                <img
                                  alt="tubo BIAFINE"
                                  className="img_text1 resize1"
                                  src="/sites/default/files/BiafinePT_15.png"
                                />
                                <p className="textoBlocoCinzaEscuro">
                                  <strong>Ingredientes:</strong>
                                </p>
                                <ul>
                                  <li className="textoBlocoInicialCinza">
                                    <strong>Glicerina</strong> conhecida pelas
                                    suas propriedades hidratantes
                                  </li>
                                  <li className="textoBlocoInicialCinza">
                                    <strong>
                                      Ácido hialurónico + centelha asiática{" "}
                                    </strong>
                                    conhecidos pelas suas propiedades
                                    reparadoras
                                  </li>
                                  <li className="textoBlocoInicialCinza">
                                    <strong>Gluconato de zinco </strong> que
                                    desempenha um papel na ação antibacteriana
                                    da fórmula
                                  </li>
                                  <li className="textoBlocoInicialCinza">
                                    <strong>Pantenol </strong>conhecido pelas
                                    suas propriedades calmantes
                                  </li>
                                </ul>
                                <p className="textoBlocoCinzaEscuro">
                                  Sem fragrância/silício/óleos minerais
                                  <br />
                                  Recomendamos que consulte a lista de
                                  ingredientes que consta na embalagem do
                                  produto.
                                </p>
                              </div>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <footer></footer>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="clear" />
          </div>
          {/* <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html:
                "\njQuery(window).load(function() {\njQuery('.BlocoPagInt1-textoVerdeClaro').each(function() {\nvar url = jQuery(this).attr('href');\n});\n});\n"
            }}
          /> 
          <div id="bloco_geral_pag_interior1" className="container">
            <div id="bloco_pag_interior1" className="container">
              <div
                id="block_qd_e_como_aplicar"
                className="sidebar five columns"
              >
                <div className="region region-block-pag-interior1">
                  <div
                    id="block-views-bloco-pag-interior1-block"
                    className="block block-views"
                  >
                    <div className="content">
                      <div className="view view-bloco-pag-interior1 view-id-bloco_pag_interior1 view-display-id-block view-dom-id-7d4bea3bb157d96455254c8758fe90ab">
                        <div className="view-content">
                          <a href="/queimaduras-do-1-grau">
                            <div className="views-row views-row-1 views-row-odd views-row-first blocos-paginterior1">
                              <div className="views-field views-field-field-imagem-bloco-pag-interior1">
                                {" "}
                                <div className="field-content img_textos2">
                                  <img
                                    typeof="foaf:Image"
                                    src="/sites/default/files/img_pag_como_funciona-bloco-1-v3_0.png"
                                    width="259"
                                    height="150"
                                    alt
                                  />
                                </div>
                              </div>
                              <div className="views-field views-field-field-url-bloco-pag-interior1">
                                {" "}
                                <div className="field-content">
                                  <a
                                    href="/queimaduras-do-1-grau"
                                    className="BlocoPagInt1-textoVerdeClaro"
                                  >
                                    Queimaduras do 1º grau
                                </a>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="/feridas-cutaneas">
                            <div className="views-row views-row-2 views-row-even blocos-paginterior1">
                              <div className="views-field views-field-field-imagem-bloco-pag-interior1">
                                {" "}
                                <div className="field-content img_textos2">
                                  <img
                                    typeof="foaf:Image"
                                    src="/sites/default/files/img_pag_como_funciona-bloco-2-259x150.png"
                                    width="259"
                                    height="150"
                                    alt
                                  />
                                </div>
                              </div>
                              <div className="views-field views-field-field-url-bloco-pag-interior1">
                                {" "}
                                <div className="field-content">
                                  <a
                                    href="/feridas-cutaneas"
                                    className="BlocoPagInt1-textoVerdeClaro"
                                  >
                                    Feridas cutâneas
                                </a>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="/eritrema-solar">
                            <div className="views-row views-row-3 views-row-odd views-row-last blocos-paginterior1">
                              <div className="views-field views-field-field-imagem-bloco-pag-interior1">
                                {" "}
                                <div className="field-content img_textos2">
                                  <img
                                    typeof="foaf:Image"
                                    src="/sites/default/files/img_pag_como_funciona-bloco-4-259x150_0.png"
                                    width="259"
                                    height="149"
                                    alt
                                  />
                                </div>
                              </div>
                              <div className="views-field views-field-field-url-bloco-pag-interior1">
                                {" "}
                                <div className="field-content">
                                  <a
                                    href="/eritrema-solar"
                                    className="BlocoPagInt1-textoVerdeClaro"
                                  >
                                    Eritema solar
                                </a>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="div_branca" />
          </div>*/}
          <div id="copyright_geral" className="container">
            <div id="copyright" className="container">
              <div className="background_img_interior" />
              <div className="rodape_esq">
                <div className="region region-footer">
                  <div id="block-block-1" className="block block-block">
                    <div className="content">
                      <p>
                        www.biafine.pt © Johnson &amp; Johnson Limitada.
                        PT/BIA/16-1068
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodapeDir rodape_dir">
                <div className="region region-footer-menu">
                  <div
                    id="block-menu-menu-menu-rodape"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/politica-de-privacidade" title>
                            POLÍTICA DE PRIVACIDADE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/cookie-policy" title>
                            POLÍTICA DE COOKIES
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/aviso-legal" title>
                            AVISO LEGAL
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/mapa-do-site" title>
                            MAPA DO SITE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/contactos" title>
                            CONTACTOS
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodape_centro">
                <div className="region region-footer-center">
                  <div id="block-block-4" className="block block-block">
                    <div className="content">
                      <div>
                        Este site é publicado pela Johnson &amp; Johnson
                        Limitada única responsável pelo seu
                        conteúdo.&nbsp;Destina-se a utilizadores em Portugal.
                      </div>
                      <div>
                        V03 BIAFINE® 6,7 mg/g, emulsão cutânea. Trolamina.
                        Medicamento não sujeito a receita médica. Indicado em
                        queimaduras do 1º grau, eritema solar e feridas cutâneas
                        superficiais não infetadas. BIAFINE® não confere
                        proteção solar e deve ser afastado dos olhos. Não deve
                        ser utilizado como um produto cosmético em pele
                        saudável. Contém parabenos, propilenoglicol e sorbato de
                        potássio que podem originar reações cutâneas ou
                        alérgicas. Contraindicado em doentes com
                        hipersensibilidade à substância ativa ou a qualquer um
                        dos excipientes e em feridas com hemorragia e infetadas.
                        Leia cuidadosamente as informações constantes da
                        embalagem e do folheto informativo. Em caso de dúvida ou
                        de persistência dos sintomas consulte o seu médico ou
                        farmacêutico. Johnson &amp; Johnson Lda. Lagoas Park,
                        Edifício 9, 2740-262 Porto Salvo. NUIPC 500 153 370.
                        <br />
                        &nbsp;
                      </div>
                      <div>&nbsp;</div>
                      <div className="last leaf">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Configuração de cookies
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
